exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-en-tsx": () => import("./../../../src/pages/about.en.tsx" /* webpackChunkName: "component---src-pages-about-en-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-contact-en-tsx": () => import("./../../../src/pages/contact.en.tsx" /* webpackChunkName: "component---src-pages-contact-en-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-history-en-tsx": () => import("./../../../src/pages/history.en.tsx" /* webpackChunkName: "component---src-pages-history-en-tsx" */),
  "component---src-pages-history-tsx": () => import("./../../../src/pages/history.tsx" /* webpackChunkName: "component---src-pages-history-tsx" */),
  "component---src-pages-index-en-tsx": () => import("./../../../src/pages/index.en.tsx" /* webpackChunkName: "component---src-pages-index-en-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-lime-en-tsx": () => import("./../../../src/pages/lime.en.tsx" /* webpackChunkName: "component---src-pages-lime-en-tsx" */),
  "component---src-pages-lime-tsx": () => import("./../../../src/pages/lime.tsx" /* webpackChunkName: "component---src-pages-lime-tsx" */),
  "component---src-pages-privacy-policy-en-tsx": () => import("./../../../src/pages/privacy-policy.en.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-en-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-production-en-tsx": () => import("./../../../src/pages/production.en.tsx" /* webpackChunkName: "component---src-pages-production-en-tsx" */),
  "component---src-pages-production-tsx": () => import("./../../../src/pages/production.tsx" /* webpackChunkName: "component---src-pages-production-tsx" */)
}

